/* You can add global styles to this file, and also import other style files */

* {
  font-family: "Josefin Sans", sans-serif;
}

body {
  position: relative;
  background: url("assets/images/Background.png") no-repeat;
  background-size: cover;
}


.fancy-text {
  font-family: "Neonderthaw", cursive;
}

p {
  font-size: 1.2rem;
  font-weight: 300;
}

